import React, { useState } from 'react';
import s from '../TagItem/TagItem.module.scss';
import { Dropdown, Menu } from 'antd';
import { ReactComponent as ThreeDotsIcon } from 'Assets/icons/threeDots.svg';
import { ReactComponent as CrossIcon } from 'Assets/icons/cross.svg';
import { ReactComponent as TagIcon } from 'Assets/icons/Tag.svg';
import { ReactComponent as AttentionIcon } from '../../../Assets/icons/alert.svg';
import { graphQlCall } from '../../../graphql/utils';
import QUERIES from 'graphql/queries';
import Button from '../../../UILib/Button/Button';

interface ITagItem {
  tagName: string;
  tagCount: number;
  tagCreatedAt: number;
  tagList: {
    tag: {
      _id: string;
    };
  };
  fetchTagCount: () => void;
  setSmartSwitch: (value: boolean) => void;
}

export function TagItem(props: ITagItem) {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const getFormattedDate = (timestamp: number): string => {
    const date = new Date(timestamp * 1000);

    const options: any = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZoneName: 'short',
    };

    return date.toLocaleString('en-US', options);
  };

  const handleOpen = () => {
    setPopupOpen(true);
  };

  const handleDeleteTag = async () => {
    try {
      await graphQlCall({
        queryTemplateObject: QUERIES.DELETE_CONTACT_TAG,
        values: {
          tagId: props.tagList.tag._id,
        },
        headerType: 'USER-AUTH',
      });
      props.fetchTagCount();
      setPopupOpen(false);
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  const formattedDate = getFormattedDate(props.tagCreatedAt);

  const menu = (
    <Menu className={s.tagMenu}>
      <Menu.Item onClick={handleOpen}>Delete Tag</Menu.Item>
    </Menu>
  );

  const PopupComponent = () => {
    return (
      <div className={s.popup}>
        <div className={s.popupInside}>
          <button onClick={() => setPopupOpen(false)}>
            <CrossIcon />
          </button>
          <AttentionIcon />
          <p>
            Are you sure you want to <br />
            delete the tag?
          </p>
          <div className={s.tag}>
            <span>
              <TagIcon />
              {props.tagName}
            </span>
          </div>
          <span className={s.text}>
            All connections of this tag with contacts will be
            <br /> irrevocably deleted.
          </span>
          <div className={s.Btns}>
            <Button className={s.whiteBtn} onClick={() => setPopupOpen(false)}>
              Cancel
            </Button>
            <Button className={s.blueBtn} onClick={handleDeleteTag}>
              Delete
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={s.person}>
      {isPopupOpen ? <PopupComponent /> : <></>}
      <input type="checkbox" />
      <div key={props.tagList.tag._id} className={s.tagDisplay}>
        <div>{props.tagName} </div>
        <div>{props.tagCount} </div>
        <div>{formattedDate} </div>
      </div>
      <Dropdown overlay={menu} className={s.antdDrop} trigger={['click']}>
        <div className={s.dots}>
          <ThreeDotsIcon />
        </div>
      </Dropdown>
    </div>
  );
}
