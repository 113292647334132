export const GET_ALL_EPISODES_OF_PODCAST = {
  operationName: 'getPodcastEpisodes',
  query: `query getPodcastEpisodes ($id: String!) {
            getPodcastEpisodes(id: $id) {
              _id
              name
              tone
              voice
              prompt
              text
              audioUrl
              coverData
              image
              status
              sections {
                _id
                title
                text
              }
              music {
                id
                introUrl
                outroUrl
                transitionUrl
                introStart
                transitionStart
                transitionEnd
                outroEnd
              }
              createdAt
              updatedAt
            }
          }`,
};
