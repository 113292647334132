import { Dropdown } from 'antd';
import { useState } from 'react';
import { clsx } from 'clsx';
import { connect } from 'react-redux';
import { IBook } from 'types';
import { CONSULT_URL } from 'Constants';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import DotsIcon from 'Assets/icons/book/dots.svg';
import bookShadow from 'Assets/icons/book-shadow.svg';
import PlaceholderIcon from 'Assets/icons/book/placeholder.svg';
import { ReactComponent as UrlIcon } from 'Assets/icons/book/copy.svg';
import { ReactComponent as PdfIcon } from 'Assets/icons/Format/File PDF.svg';
import { ReactComponent as DocxIcon } from 'Assets/icons/Format/File DOCX.svg';
import { ReactComponent as EpubIcon } from 'Assets/icons/Format/File ePUB.svg';
import { ReactComponent as PencilIcon } from 'Assets/icons/edit2.svg';
import { ReactComponent as DeleteIcon } from 'Assets/icons/book/x.svg';
import { ReactComponent as MarkdownIcon } from 'Assets/icons/Format/File Markdown.svg';
import { ReactComponent as DownloadIcon } from 'Assets/icons/book/download.svg';
import { ReactComponent as DuplicateIcon } from 'Assets/icons/contextMenu/duplicateIcon.svg';
import { ReactComponent as DuplicateInAnotherFunnelIcon } from 'Assets/icons/contextMenu/duplicateInAnotherFunnelIcon.svg';
import { getActiveSubId, getUserName } from 'utils/Utils';
import { RootState } from 'store/rootReducer';
import { IAccount } from 'Components/Accounts/types';
import CopyBookPopup from '../Popups/CopyBookPopup/CopyBookPopup';

import s from './BookCard.module.scss';

interface IProps {
  book: IBook;
  onSettingsClick: () => void;
  onCopyUrl: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
  onStartExport: (exportFormat: string) => void;
  onShowPdf: () => void;
  accounts: IAccount[];
  subAccounts: IAccount[];
}

enum BookCardMenuItems {
  Settings,
  CopyPublicUrl,
  Duplicate,
  DownloadPdf,
  Delete,
  Export,
  ExportEpub,
  ExportDocx,
  ExportMarkdown,
  ShowPdf,
  DuplicateOtherBook,
}

const BookCard = (props: IProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [duplicatePopupOpen, setDuplicatePopupOpen] = useState(false);

  const handleEdit = () => {
    window.open(`${CONSULT_URL}/edit/book-editor/${props.book._id}`, '_self');
  };

  const cardStyle = props.book.coverImageUrl
    ? {
        backgroundImage: `url("${props.book.coverImageUrl}")`,
        backgroundSize: 'cover',
      }
    : {};

  const handleMenuClick = (e: any) => {
    setOpenMenu(false);
    switch (
      +e.key // needs to add '+' to fight with glitch in type script or Dropdown menu component that will need to convert e.key in to number
    ) {
      case BookCardMenuItems.Settings: {
        props.onSettingsClick();
        break;
      }
      case BookCardMenuItems.CopyPublicUrl: {
        props.onCopyUrl();
        break;
      }
      case BookCardMenuItems.Duplicate: {
        props.onDuplicate();
        break;
      }
      case BookCardMenuItems.DuplicateOtherBook: {
        setDuplicatePopupOpen(true);
        break;
      }
      case BookCardMenuItems.DownloadPdf: {
        props.onStartExport('pdf');
        break;
      }
      case BookCardMenuItems.Delete: {
        props.onDelete();
        break;
      }
      case BookCardMenuItems.ExportDocx: {
        props.onStartExport('docx');
        break;
      }
      case BookCardMenuItems.ExportEpub: {
        props.onStartExport('epub');
        break;
      }
      case BookCardMenuItems.ExportMarkdown: {
        props.onStartExport('markdown');
        break;
      }
      case BookCardMenuItems.ShowPdf: {
        props.onShowPdf();
        break;
      }
    }
  };

  const menuDidOpen = (open: boolean) => {
    setOpenMenu(open);
  };

  const menuItems = [
    // {
    //   label: 'Settings',
    //   key: BookCardMenuItems.Settings,
    //   icon: <SettingsIcon />,
    // },
    {
      label: 'Copy Public URL',
      key: BookCardMenuItems.CopyPublicUrl,
      icon: <UrlIcon />,
    },
    {
      label: 'Duplicate',
      key: BookCardMenuItems.Duplicate,
      icon: <DuplicateIcon />,
    },
    {
      label: 'Copy to Another Account',
      key: BookCardMenuItems.DuplicateOtherBook,
      icon: <DuplicateInAnotherFunnelIcon />,
    },
    {
      label: 'View Public PDF',
      key: BookCardMenuItems.ShowPdf,
      icon: <PdfIcon />,
    },
    {
      label: 'Download',
      key: BookCardMenuItems.Export,
      icon: <DownloadIcon />,
      children: [
        {
          label: 'PDF',
          key: BookCardMenuItems.DownloadPdf,
          icon: <PdfIcon />,
        },
        {
          key: BookCardMenuItems.ExportDocx,
          label: 'DOCX',
          icon: <DocxIcon />,
        },
        {
          key: BookCardMenuItems.ExportEpub,
          label: 'ePUB',
          icon: <EpubIcon />,
        },
        {
          key: BookCardMenuItems.ExportMarkdown,
          label: 'Markdown',
          icon: <MarkdownIcon />,
        },
      ],
    },
    {
      label: 'Delete',
      key: BookCardMenuItems.Delete,
      icon: <DeleteIcon />,
    },
  ];

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  const handleDuplicate = async (payload: {
    bookId: string;
    targetAccountOwner: string;
    sourceAccountOwner: string;
  }) => {
    try {
      await graphQlCall({
        queryTemplateObject: QUERIES.DUPLICATE_BOOK_TO_OTHER_ACCOUNT,
        values: {
          bookId: payload.bookId,
          targetAccountOwner: payload.targetAccountOwner,
          sourceAccountOwner: payload.sourceAccountOwner,
        },
      });
    } catch (error) {
      console.error('Error in duplicating book:', error);
    }
  };

  let sub = getUserName();
  const subUserId = getActiveSubId();
  if (subUserId) {
    const account = props.subAccounts.find((item) => item._id === subUserId);
    if (account) {
      sub = account.owner;
    }
  }

  return (
    <div
      className={s.cardContent}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{ ...cardStyle }}
    >
      <img className={s.bookShadow} src={bookShadow} alt="book shadow" />
      {!props.book.coverImageUrl && (
        <div className={s.imageLessCard}>
          <img
            className={s.bookShadowLessCard}
            src={bookShadow}
            alt="book shadow"
          />
          <img src={PlaceholderIcon} alt="book" />
          <h5 className={s.bookTitle}>{props.book.title}</h5>
        </div>
      )}
      <div className={clsx(s.overlay, { [s.hovered]: hovered || openMenu })}>
        <div className={s.shadowBlock}></div>
        <div className={s.editIcon} onClick={() => handleEdit()}>
          <PencilIcon fill="#3e3e3e" />
        </div>
        <Dropdown
          menu={menuProps}
          trigger={['click']}
          overlayClassName={'custom'}
          onOpenChange={menuDidOpen}
        >
          <div className={s.dots} onClick={(e) => e.preventDefault()}>
            <img src={DotsIcon} alt="..." />
          </div>
        </Dropdown>
      </div>
      {props.book.progress &&
      props.book.progress > 0 &&
      props.book.progress < 100 ? (
        <div
          className={s.progress}
          style={{ width: `${props.book.progress * 2}px` }}
        >
          <span>{props.book.progress} % </span>
        </div>
      ) : null}
      <CopyBookPopup
        owner={sub}
        bookId={props.book._id}
        open={duplicatePopupOpen}
        accounts={props.accounts}
        onClose={() => setDuplicatePopupOpen(false)}
        onDuplicate={handleDuplicate}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  accounts: state.accounts.accounts,
  subAccounts: state.accounts.accounts,
});

export default connect(mapStateToProps)(BookCard);
