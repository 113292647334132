export const DELETE_EPISODE_SECTION_MUTATION = {
  operationName: 'deleteEpisodeSection',
  query: `mutation deleteEpisodeSection($episodeId: String!, $sectionId: String!) {
          deleteEpisodeSection(episodeId: $episodeId, sectionId: $sectionId) {
            _id
            name
            tone
            voice
            prompt
            text
            audioUrl
            coverData
            image
            sections {
              _id
              title
              text
            }
            description
            createdAt
            updatedAt
          }
        }`,
};
