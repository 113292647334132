import { useEffect, useState } from 'react';
import uuid from 'react-uuid';

import { ReactComponent as CrossIcon } from 'Assets/icons/cross.svg';
import { graphQlCall } from 'graphql/utils';
import { Input, Select } from 'antd';
import { Tag } from '../Helper/types';
import QUERIES from 'graphql/queries';
import s from 'Pages/PageContacts/AddContact/AddContact.module.scss';

interface ITag {
  value: string;
  label: string;
}

interface ICreateContacts {
  _id: string;
  email: string;
  phone?: string;
  tagsId: string[];
  data?: string;
}

interface IAddContact {
  isPopupOpen: boolean;
  setPopupOpen: (isOpen: boolean) => void;
  funnelId: string;
  pageId: string;
  uniqueTags: Tag[];
  fetchUserContacts: () => void;
}

const AddContactsPopup = (props: IAddContact) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [addr, setAddr] = useState('');
  const [selectedtags, setSelectedTags] = useState<string[]>([]);
  const [tags, setTags] = useState<ITag[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTags(props.uniqueTags.map((e) => ({ value: e._id, label: e.name })));
  }, [props.uniqueTags]);

  const createContact = async (data: ICreateContacts) => {
    return await graphQlCall({
      headerType: 'USER-AUTH',
      queryTemplateObject: QUERIES.CREATE_CONTACT_CONSOLE,
      values: data,
    });
  };

  const handleClosePopup = () => {
    clearForm();
    props.setPopupOpen(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const contactData = { addr, country, name };
    const serializedData = JSON.stringify(contactData);

    const tempId = uuid();
    const data: ICreateContacts = {
      _id: tempId,
      email,
      tagsId: selectedtags,
      phone,
      data: serializedData,
    };

    try {
      const newContact = await createContact(data);
      props.fetchUserContacts();
      handleClosePopup();
    } finally {
      setLoading(false);
    }
  };

  const clearForm = () => {
    setEmail('');
    setName('');
    setPhone('');
    setCountry('');
    setAddr('');
    setSelectedTags([]);
    setTags([]);
    setLoading(false);
  };

  return (
    <div className={props.isPopupOpen ? s.popup : s.open}>
      <div className={s.popupContent}>
        <span className={s.close} onClick={handleClosePopup}>
          <CrossIcon />
        </span>
        <h2>Add Contacts</h2>
        <p>Fill in the details of your new contact below.</p>
        <form onSubmit={handleSubmit}>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Full Name"
            required
          />
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <Input
            type="number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone Number (Optional)"
          />
          <Input
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            placeholder="Country (Optional)"
          />
          <Input
            value={addr}
            onChange={(e) => setAddr(e.target.value)}
            placeholder="Address (Optional)"
          />
          <Select
            mode="multiple"
            placeholder="Add Tag"
            className={s.select}
            options={tags}
            onChange={(e) => setSelectedTags(e)}
            optionFilterProp="label"
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Adding...' : 'Add Contact'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddContactsPopup;
