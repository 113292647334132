import { PayloadAction } from '@reduxjs/toolkit';
import { IPodcast, IPodcastEpisode, IPodcastWithTotal } from 'types';
import {
  PODCASTS_LOADING,
  EPISODES_LOADING,
  SET_USER_PODCASTS,
  SET_PODCAST_EPISODES,
  AUDIO_UPDATE,
} from './podcastActions';

export interface IPodcastsState {
  items: IPodcastWithTotal;
  podcastEpisodes: IPodcastEpisode[];
  podcastsLoading: boolean;
  episodesLoading: boolean;
  currentPlayingAudio: string | null;

  // popup: {
  //   open: boolean;
  //   initialState?: IUserProduct;
  // };
}

const initialState: IPodcastsState = {
  items: { podcasts: [], total: 0 },
  podcastEpisodes: [],
  podcastsLoading: false,
  episodesLoading: false,
  currentPlayingAudio: null,
  // popup: {
  //   open: false,
  //   initialState: undefined,
  // },
};

const podcastReducer = (
  state = initialState,
  action: PayloadAction<
    IPodcast[] | IPodcastEpisode[] | boolean | IPodcastWithTotal | string | null
  >
): IPodcastsState => {
  switch (action.type) {
    case SET_USER_PODCASTS:
      const items = action.payload as IPodcastWithTotal;
      return {
        ...state,
        items,
      };
    case SET_PODCAST_EPISODES:
      const podcastEpisodes = action.payload as IPodcastEpisode[];
      return {
        ...state,
        podcastEpisodes,
      };
    case PODCASTS_LOADING:
      return {
        ...state,
        podcastsLoading: action.payload as boolean,
      };
    case EPISODES_LOADING:
      return {
        ...state,
        episodesLoading: action.payload as boolean,
      };
    case AUDIO_UPDATE:
      return {
        ...state,
        currentPlayingAudio: action.payload as string | null,
      };
    //   case UPDATE_POPUP:
    //     let newPopup = { ...initialState.popup };
    //     if (typeof action.payload !== 'boolean') {
    //       newPopup = { ...newPopup, ...action.payload };
    //     }
    //     return {
    //       ...state,
    //       popup: newPopup,
    //     };
    default:
      return state;
  }
};

export { initialState, podcastReducer };
