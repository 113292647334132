import clsx from 'clsx';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { ReactComponent as MoveIcon } from 'Assets/icons/move.svg';
import { ReactComponent as MoreIcon } from 'Assets/icons/more.svg';
import EditableLabel from 'UILib/EditableLabel/EditableLabel';
import Tooltip from 'UILib/Tooltip/Tooltip';

import styles from './SectionElement.module.scss';

interface IProps {
  indicator: ReactNode;
  text: string;
  selected: boolean;
  tooltipElements?: {
    title: string;
    icon?: ReactNode;
    onClick: () => void;
  }[];
  onClick: () => void;
  onSectionTitleChange: (value: string) => void;
}

const SectionElement = ({
  indicator,
  text,
  selected,
  tooltipElements,
  onClick,
  onSectionTitleChange,
}: IProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [sectionTitle, setSectionTitle] = useState(text);

  const buttonRef = useRef(null);

  useEffect(() => {
    setSectionTitle(text);
  }, [text]);

  return (
    <div
      className={clsx(styles.container, { [styles.selected]: selected })}
      onClick={onClick}
    >
      <div className={styles.indicator}>
        <div className={styles.defaultIcon}>{indicator}</div>
        <MoveIcon className={styles.hoverIcon} />
      </div>
      <EditableLabel
        theme="dark"
        value={sectionTitle}
        onChange={setSectionTitle}
        onOutsideClick={() => onSectionTitleChange(sectionTitle)}
      />
      <div className={styles.moreIcon}>
        <MoreIcon
          ref={buttonRef}
          onClick={() => {
            if (tooltipElements && tooltipElements.length > 0) {
              setIsTooltipVisible(!isTooltipVisible);
            }
          }}
        />
        <Tooltip
          open={isTooltipVisible}
          targetRef={buttonRef}
          position="right"
          onOutsideClick={() => setIsTooltipVisible(false)}
          content={
            <div className={styles.tooltipContainer}>
              {tooltipElements?.map((element, index) => (
                <div
                  key={index}
                  className={styles.tooltipElement}
                  onClick={() => {
                    element.onClick();
                    setIsTooltipVisible(false);
                  }}
                >
                  {element.icon} {element.title}
                </div>
              ))}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default SectionElement;
