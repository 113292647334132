import { FETCH_FUNNELS_QUERY } from './payloads/fetchFunnels';
import { FETCH_FUNNELS_NAME_QUERY } from './payloads/fetchFunnelsName';
import { GET_FUNNELS_WITH_PAGES_QUERY } from './payloads/getFunnelsWithPages';
import { ADD_BLANK_FUNNEL_MUTATION } from './payloads/addBlankFunnel';
import { UPDATE_FUNNEL_MUTATION } from './payloads/updateFunnel';
import {
  CHANGE_FUNNEL_POSITION_MUTATION,
  CHANGE_FUNNEL_POSITIONS_MUTATION,
} from './payloads/changeFunnelPosition';
import { CREATE_PAGE_FROM_TEMPLATE_MUTATION } from './payloads/createPageFromTemplate';
import { UPDATE_PAGE_MUTATION } from './payloads/updatePage';
import { DUPLICATE_PAGE_MUTATION } from './payloads/duplicatePage';
import { SET_PAGES_ORDER_MUTATION } from './payloads/setPagesOrder';
import { GET_USER_DETAILS_QUERY } from './payloads/getUserDetails';
import { CREATE_USER_MUTATION } from './payloads/createUser';
import { GET_ALL_FUNNEL_IDS_QUERY } from './payloads/getAllFunnelsIds';
import { GET_STATISTIC_QUERY } from './payloads/getStatistic';
import { GET_USER_DOMAINS_QUERY } from './payloads/getUserDomains';
import { GET_MAIL_SENDERS_QUERY } from './payloads/getMailSenders';
import { GET_MAIL_DOMAINS_QUERY } from './payloads/getMailDomains';
import { ADD_DOMAIN_MUTATION } from './payloads/addDomain';
import { ADD_MAILGUN_SENDER } from './payloads/addMailgunSender';
import { REFRESH_MAIL_DOMAIN_RECORDS } from './payloads/refreshMailDomainRecords';
import { CHECK_DOMAIN_STATUS_QUERY } from './payloads/checkDomainStatus';
import { DELETE_DOMAIN_MUTATION } from './payloads/deleteDomain';
import { DISCONNECT_FUNNEL_DOMAIN_MUTATION } from './payloads/disconnectFunnelFromDomain';
import { UPDATE_DOMAIN_MUTATION } from './payloads/updateDomain';
import { GET_CURRENT_USER_QUERY } from './payloads/getCurrentUserDetails';
import { UPDATE_USER_MUTATION } from './payloads/updateUser';
import { GET_ALL_STRIPE_SUBSCRIPTIONS_QUERY } from './payloads/getAllStripeSubscriptions';
import { STRIPE_SUBSCRIBE_MUTATION } from './payloads/subscribeCurrentUser';
import { STRIPE_SESSION_INFO_QUERY } from './payloads/getSessionInfo';
import { GET_STATISTIC_GRAPH_DATA_QUERY } from './payloads/getStatisticGraphData';
import { GET_STATISTIC_FUNNEL_GRAPH_DATA_QUERY } from './payloads/getStatisticFunnelGraphData';
import { GET_ALL_BOOK_REQUESTS } from './payloads/getAllBookRequests';
import { GET_USER_BOOKS } from './payloads/getUserBooks';
import { DELETE_BOOK } from './payloads/deleteBook';
import { CREATE_AI_TEXT_TOPIC_MUTATION } from './payloads/createAiTextTopic';
import { UPDATE_AI_TEXT_TOPIC_MUTATION } from './payloads/updateAiTextTopic';
import { DELETE_AI_TEXT_TOPIC_MUTATION } from './payloads/deleteAiTextTopic';
import { GET_ALL_AI_TEXT_TOPICS } from './payloads/getAllAiTextTopicsByUser';
import { GET_AI_TOPIC_QUERY } from './payloads/getAiTextTopicsByUser';
import { CREATE_AI_TEXT_REQUEST } from './payloads/createAiTextRequest';
import { UPDATE_AI_TEXT_REQUEST } from './payloads/updateAiTextRequest';
import { GET_AI_REQUEST_BY_TOPIC } from './payloads/getAiTextRequestByTopic';
import { DELETE_AI_REQUEST } from './payloads/deleteAiTextRequest';
import { DUPLICATE_BOOK_MUTATION } from './payloads/duplicateBook';
import { DUPLICATE_FUNNEL_MUTATION } from './payloads/duplicateFunnel';
import { GET_ALL_AI_TEMPLATES } from './payloads/getAllAiTemplates';
import { GET_AI_TEMPLATE } from './payloads/getOneAiTemplate';
import { UPDATE_AI_TEMPLATE } from './payloads/updateAiTemplate';
import { DELETE_AI_TEMPLATE } from './payloads/deleteAiTemplate';
import { DELETE_EPISODE_MUTATION } from './payloads/podcaster/deleteEpisode';
import { DELETE_EPISODE_SECTION_MUTATION } from './payloads/podcaster/deleteEpisodeSection';
import { CREATE_AI_TEMPLATE } from './payloads/createAiTemplate';
import { CHECK_SUBSCRIPTION_ACCESS } from './payloads/checkSubscriptionAccess';
import { GET_TOTAL_COMMON_VIEWS } from './payloads/getTotalCommonViews';
import { GET_TOTAL_PAGE_VIEWS } from './payloads/getTotalPageViews';
import { GET_TOTAL_GRAPH_DATA } from './payloads/getTotalGraphData';
import { ADD_SUB_ACCOUNT } from './payloads/addSubAccount';
import { EDIT_SUB_ACCOUNT } from './payloads/editSubAccount';
import { GET_SUB_ACCOUNTS } from './payloads/getAgencySubAccounts';
import { CREATE_TOKEN } from './payloads/createToken';
import { REFRESH_TOKEN } from './payloads/refreshToken';
import { DELETE_SUB_ACCOUNT } from './payloads/deleteSubAccount';
import { TRANSFER_TO_SEPARATE_ACCOUNT } from './payloads/transferToSeparate';
import { INITIATE_TRANSFER_BY_AGENCY } from './payloads/initiateTransferToAgencyByAgency';
import { INITIATE_TRANSFER_BY_USER } from './payloads/initiateTransferToAgencyByUser';
import { CONFIRM_TRANSFER_TO_AGENCY } from './payloads/confirmTransferToAgency';
import { DISCONNECT_AGENCY } from './payloads/disconnectAgency';
import { GET_FUNNELS_FROM_SUB_ACCOUNT } from './payloads/getFunnelsFromSubAccount';
import { DUPLICATE_FUNNEL_TO_OTHER_ACCOUNT } from './payloads/duplicateFunnelToOtherAccount';
import { DUPLICATE_PAGE_TO_OTHER_ACCOUNT } from './payloads/duplicatePageToOtherAccount';
import { GET_OR_CREATE_USER_MUTATION } from './payloads/getOrCreateUser';
import { GET_SIGNUP_TOKEN_INFO } from './payloads/getSignupTokenInfo';
import { FINISH_SIGNUP } from './payloads/finishSignUp';
import { START_RECOVERY_PASSWORD } from './payloads/startRecoveringPassword';
import { CHANGE_USER_PASSWORD } from './payloads/changeUserPassword';
import { GET_PASSWORD_RECOVERY_TOKEN_INFO } from './payloads/getPasswordRecoveryTokenInfo';
import { REGENERATE_SIGNUP_TOKEN } from './payloads/regenerateSignUpToken';
import { GET_TEMPLATES_WITH_CATEGORIES } from './payloads/getTemplatesWithCategories';
import { GET_TEMPLATES_LIST } from './payloads/getTemplatesList';
import { UPDATE_AI_PAGE } from './payloads/udpateAiPage';
import { DELETE_AI_PAGE } from './payloads/deleteAiPage';
import { GET_ONE_AI_PAGE } from './payloads/getOneAiPage';
import { AF_CREATE_CHECKOUT_SESSION } from './payloads/afCreateCheckoutSession';
import { AF_CREATE_SETUP_INTENT_PAYMENT_UPDATE } from './payloads/afCreateSetupIntentPaymentUpdate';
import { GET_BOOKS_FOR_FUNNEL_BUILDER } from './payloads/getBooksForFunnelBuilder';
import { CHANGE_USERS_SUBSCRIPTION } from './payloads/changeUsersSubscription';
import { SET_USER_PAYMENT_METHOD } from './payloads/setUserPaymentMethod';
import { CREATE_PODCAST_MUTATION } from './payloads/podcaster/createPodcast';
import { CREATE_EPISODE_MUTATION } from './payloads/podcaster/createEpisode';
import { GET_ALL_EPISODES_OF_PODCAST } from './payloads/podcaster/getAllEpisodesOfPodcast';
import { GET_PODCAST_BY_ID } from './payloads/podcaster/getPodcastById';
import { UPDATE_EPISODE_MUTATION } from './payloads/podcaster/updateEpisodeMutation';
import { GET_USER_PODCASTS } from './payloads/podcaster/getUsersPodcasts';
import { GET_BOOKS_WITH_PAGINATION } from './payloads/getBookWithPagination';
import { CREATE_PAGE_FROM_AI_MUTATION } from './payloads/createPageFromAiMutation';
import { BOOKS_SEARCH } from './payloads/boooksSearch';
import { GET_USER_PRODUCTS } from './payloads/getUserProducts';
import { GET_PRODUCT } from './payloads/getProduct';
import { CREATE_PRODUCT_MUTATION } from './payloads/createProduct';
import { UPDATE_PRODUCT_MUTATION } from './payloads/updateProduct';
import { DELETE_PRODUCT_MUTATION } from './payloads/deleteProduct';
import { GET_USER_CONTACTS } from './payloads/getUserContacts';
import { GET_USER_CONTACTS_WITH_PAGINATION } from './payloads/getUserContactsWithPagination';
import { GET_CONTACT } from './payloads/getContact';
import { CREATE_CONTACT } from './payloads/createContact';
import { UPDATE_CONTACT } from './payloads/updateContact';
import { DELETE_CONTACT } from './payloads/deleteContact';
import { DELETE_PODCAST } from './payloads/podcaster/deletePodcast';
import { ADD_CONTACT_TAG } from './payloads/addContactTag';
import { DELETE_CONTACT_TAG } from './payloads/deleteContactTag';
import { CREATE_SMART_LIST } from './payloads/createSmartList';
import { DELETE_SMART_LIST } from './payloads/deleteSmartList';
import { GET_PODCAST_EPISODE } from './payloads/podcaster/getPodcastEpisode';
import { GET_USER_SMART_LISTS } from './payloads/getUserSmartLists';
import { GET_USER_CONTACT_TAGS } from './payloads/getUserContactTags';
import { CREATE_CONTACT_CONSOLE } from './payloads/createContactConsole';
import { DELETE_MAIL_SENDER_MUTATION } from './payloads/deleteMailSender';
import { GET_USER_TOPICS_WITH_PAGINATION } from './payloads/getUserTopicsWithPagination';
import { GET_USER_CONTACT_TAGS_WITH_COUNT } from './payloads/getUserContactTagsWithCount';
import { DUPLICATE_BOOK_TO_OTHER_ACCOUNT } from './payloads/duplicateBookToOtherAccount';
import { GET_USER_CONTACT_TAGS_WITH_PAGINATION } from './payloads/getUserContactTagsWithPagination';
import { FETCH_FUNNELS_WITH_PAGINATION_QUERY } from './payloads/fetchFunnelsWithPagination';
import { UPDATE_EPISODE_SECTION_MUTATION } from './payloads/podcaster/updateEpisodeSection';
import { GET_PODCASTS_WITH_PAGINATION } from './payloads/podcaster/getPodcastWithPagination';
import { REORDER_EPISODE_SECTIONS } from './payloads/podcaster/reorderEpisodeSections';
import { UPDATE_PODCAST_MUTATION } from './payloads/podcaster/updatePodcast';
import { ADD_EPISODE_SECTION } from './payloads/podcaster/addEpisodeSection';
import { GET_EPISODE_INFO } from './payloads/podcaster/getEpisodeInfo';
import { CREATE_CUSTOMER_PORTAL_SESSION } from './payloads/createCustomerPortalSession';

const queries = {
  FETCH_FUNNELS_QUERY,
  FETCH_FUNNELS_NAME_QUERY,
  ADD_BLANK_FUNNEL_MUTATION,
  UPDATE_FUNNEL_MUTATION,
  CHANGE_FUNNEL_POSITION_MUTATION,
  CHANGE_FUNNEL_POSITIONS_MUTATION,
  CREATE_PAGE_FROM_TEMPLATE_MUTATION,
  CREATE_PAGE_FROM_AI_MUTATION,
  UPDATE_PAGE_MUTATION,
  DUPLICATE_PAGE_MUTATION,
  SET_PAGES_ORDER_MUTATION,
  GET_USER_DETAILS_QUERY,
  CREATE_USER_MUTATION,
  GET_ALL_FUNNEL_IDS_QUERY,
  GET_STATISTIC_QUERY,
  GET_STATISTIC_GRAPH_DATA_QUERY,
  GET_STATISTIC_FUNNEL_GRAPH_DATA_QUERY,
  REFRESH_MAIL_DOMAIN_RECORDS,
  GET_USER_DOMAINS_QUERY,
  GET_MAIL_SENDERS_QUERY,
  GET_MAIL_DOMAINS_QUERY,
  ADD_DOMAIN_MUTATION,
  ADD_MAILGUN_SENDER,
  CHECK_DOMAIN_STATUS_QUERY,
  DELETE_DOMAIN_MUTATION,
  DELETE_MAIL_SENDER_MUTATION,
  DISCONNECT_FUNNEL_DOMAIN_MUTATION,
  UPDATE_DOMAIN_MUTATION,
  GET_CURRENT_USER_QUERY,
  UPDATE_USER_MUTATION,
  GET_ALL_STRIPE_SUBSCRIPTIONS_QUERY,
  STRIPE_SUBSCRIBE_MUTATION,
  STRIPE_SESSION_INFO_QUERY,
  DUPLICATE_BOOK_MUTATION,
  GET_ALL_BOOK_REQUESTS,
  GET_USER_BOOKS,
  DELETE_BOOK,
  GET_FUNNELS_WITH_PAGES_QUERY,
  CREATE_AI_TEXT_TOPIC_MUTATION,
  UPDATE_AI_TEXT_TOPIC_MUTATION,
  DELETE_AI_TEXT_TOPIC_MUTATION,
  GET_ALL_AI_TEXT_TOPICS,
  GET_AI_TOPIC_QUERY,
  CREATE_AI_TEXT_REQUEST,
  UPDATE_AI_TEXT_REQUEST,
  GET_AI_REQUEST_BY_TOPIC,
  DELETE_AI_REQUEST,
  DUPLICATE_FUNNEL_MUTATION,
  GET_ALL_AI_TEMPLATES,
  GET_AI_TEMPLATE,
  UPDATE_AI_TEMPLATE,
  DELETE_AI_TEMPLATE,
  CREATE_AI_TEMPLATE,
  CHECK_SUBSCRIPTION_ACCESS,
  GET_TOTAL_COMMON_VIEWS,
  GET_TOTAL_PAGE_VIEWS,
  GET_TOTAL_GRAPH_DATA,
  ADD_SUB_ACCOUNT,
  EDIT_SUB_ACCOUNT,
  GET_SUB_ACCOUNTS,
  CREATE_TOKEN,
  REFRESH_TOKEN,
  DELETE_SUB_ACCOUNT,
  TRANSFER_TO_SEPARATE_ACCOUNT,
  INITIATE_TRANSFER_BY_AGENCY,
  INITIATE_TRANSFER_BY_USER,
  CONFIRM_TRANSFER_TO_AGENCY,
  DISCONNECT_AGENCY,
  GET_FUNNELS_FROM_SUB_ACCOUNT,
  DUPLICATE_FUNNEL_TO_OTHER_ACCOUNT,
  DUPLICATE_PAGE_TO_OTHER_ACCOUNT,
  GET_OR_CREATE_USER_MUTATION,
  GET_SIGNUP_TOKEN_INFO,
  GET_PODCAST_EPISODE,
  FINISH_SIGNUP,
  START_RECOVERY_PASSWORD,
  CHANGE_USER_PASSWORD,
  GET_PASSWORD_RECOVERY_TOKEN_INFO,
  REGENERATE_SIGNUP_TOKEN,
  GET_TEMPLATES_LIST,
  UPDATE_AI_PAGE,
  DELETE_AI_PAGE,
  GET_ONE_AI_PAGE,
  GET_TEMPLATES_WITH_CATEGORIES,
  AF_CREATE_CHECKOUT_SESSION,
  AF_CREATE_SETUP_INTENT_PAYMENT_UPDATE,
  GET_BOOKS_FOR_FUNNEL_BUILDER,
  CHANGE_USERS_SUBSCRIPTION,
  SET_USER_PAYMENT_METHOD,
  CREATE_PODCAST_MUTATION,
  CREATE_EPISODE_MUTATION,
  GET_ALL_EPISODES_OF_PODCAST,
  GET_PODCAST_BY_ID,
  UPDATE_EPISODE_MUTATION,
  GET_USER_PODCASTS,
  GET_BOOKS_WITH_PAGINATION,
  BOOKS_SEARCH,
  GET_USER_PRODUCTS,
  GET_PRODUCT,
  CREATE_PRODUCT_MUTATION,
  UPDATE_PRODUCT_MUTATION,
  DELETE_PRODUCT_MUTATION,
  DELETE_EPISODE_MUTATION,
  DELETE_EPISODE_SECTION_MUTATION,
  GET_USER_CONTACTS_WITH_PAGINATION,
  GET_USER_CONTACTS,
  GET_CONTACT,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  ADD_CONTACT_TAG,
  DELETE_CONTACT_TAG,
  GET_USER_CONTACT_TAGS,
  CREATE_SMART_LIST,
  GET_USER_SMART_LISTS,
  DELETE_SMART_LIST,
  ADD_EPISODE_SECTION,
  GET_USER_CONTACT_TAGS_WITH_COUNT,
  DUPLICATE_BOOK_TO_OTHER_ACCOUNT,
  GET_USER_CONTACT_TAGS_WITH_PAGINATION,
  UPDATE_EPISODE_SECTION_MUTATION,
  REORDER_EPISODE_SECTIONS,
  CREATE_CONTACT_CONSOLE,
  DELETE_PODCAST,
  GET_USER_TOPICS_WITH_PAGINATION,
  FETCH_FUNNELS_WITH_PAGINATION_QUERY,
  GET_PODCASTS_WITH_PAGINATION,
  GET_EPISODE_INFO,
  UPDATE_PODCAST_MUTATION,
  CREATE_CUSTOMER_PORTAL_SESSION,
};

export default queries;
