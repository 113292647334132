export const UPDATE_PODCAST_MUTATION = {
  operationName: 'updatePodcast',
  query: `mutation updatePodcast(<KEYS>) {
        updatePodcast(<VALUES>) {
          _id
          name
          image
          colorsSchemeType
          templateType
          coverData
          image
          description
          createdAt
          updatedAt
        }
      }`,
};
