export const DUPLICATE_BOOK_TO_OTHER_ACCOUNT = {
  operationName: 'duplicateBookToOtherAccount',
  query: `
    mutation duplicateBookToOtherAccount(
      $bookId: String!
      $targetAccountOwner: String!
      $sourceAccountOwner: String!
    ) {
      duplicateBookToOtherAccount(
        bookId: $bookId
        targetAccountOwner: $targetAccountOwner
        sourceAccountOwner: $sourceAccountOwner
      ) {
        _id
        title
        description
        tone
        audience
        structure
        createdAt
        updatedAt
        chapters {
          title
          text
          comments
        }
        data
        coverData
        coverImageUrl
        pdfFile
        progress
        generating
        size
      }
    }
  `,
};
