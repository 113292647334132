import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import {
  deleteProductAction,
  deleteProductPopupAction,
} from 'store/products/productActions';
import Popup from 'UILib/Popup/Popup';
import Spinner from 'Components/Common/Spinner/Spinner';
import Button from '../../Components/Common/DomainButton/DomainButton';
import closeSvg from '../../Assets/icons/close.svg';
import sadSmileSvg from '../../Assets/icons/sadSmile.svg';

import s from './popup.module.scss';

interface IProps {
  isLoading?: boolean;
  popupData: { open: boolean; productId: string };
  deleteProduct: (id: string) => void;
  triggerPopup: (payload: { open?: boolean; productId?: string }) => void;
}

const DeleteProductPopup = (props: IProps) => {
  if (!props.popupData.open) {
    return null;
  }

  const handleClose = () => {
    props.triggerPopup({ productId: '', open: false });
  };

  const handleDeleteProduct = () => {
    props.deleteProduct(props.popupData.productId);
    handleClose();
  };

  return (
    <Popup onClose={handleClose}>
      <div className={s.modal}>
        <img
          className={s.closeIcon}
          src={closeSvg}
          alt="x"
          onClick={handleClose}
        />
        <img className={s.worldIcon} src={sadSmileSvg} alt="O" />
        <h1 className={s.headerText}>Are You Sure? </h1>
        <div className={s.buttonsBlock}>
          {props.isLoading ? (
            <div style={{ margin: '0 auto' }}>
              <Spinner size={50} />{' '}
            </div>
          ) : (
            <>
              <Button name="No, keep product" onClick={handleClose} />
              <Button
                name="Yes, remove"
                onClick={handleDeleteProduct}
                isBlackBtn={true}
              />
            </>
          )}
        </div>
      </div>
    </Popup>
  );
};

const mapStateToProps = (state: RootState) => ({
  popupData: state.products.deletePopup,
});

const mapDispatchToProps = {
  triggerPopup: (payload: { open?: boolean; productId?: string }) =>
    deleteProductPopupAction(payload),
  deleteProduct: (id: string) => deleteProductAction(id),
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProductPopup);
