import { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import clsx from 'clsx';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
// import { ReactComponent as DotsIcon } from 'Assets/icons/editDots.svg';
import { ReactComponent as PlayIcon } from 'Assets/icons/play-button.svg';
import { ReactComponent as PauseIcon } from 'Assets/icons/pause-button.svg';

import styles from './AudioBlock.module.scss';

interface IProps {
  selectable?: boolean;
  selected?: boolean;
  theme?: 'light' | 'dark';
  audioUrl?: string;
  imageSrc?: string;
  label?: string;
  onClick?: () => void;
  currentPlayingAudio: string | null;
  updateCurrentAudio: (audioKey: string | null) => void;
  audioId: string | null;
}

const AudioBlock = ({
  theme = 'light',
  selectable,
  selected,
  audioUrl,
  imageSrc,
  onClick,
  label,
  currentPlayingAudio,
  updateCurrentAudio,
  audioId,
}: IProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);

  useEffect(() => {
    if (wavesurfer.current) {
      wavesurfer.current.destroy();
      wavesurfer.current = null;
    }

    if (audioUrl && waveformRef.current) {
      setIsLoading(true);
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: theme === 'light' ? '#e8e8e8' : '#5a5a5a',
        progressColor: theme === 'light' ? '#3e3e3e' : '#a0a0a0',
        barWidth: 2,
        cursorWidth: 1,
        backend: 'WebAudio',
        height: 50,
        cursorColor: 'transparent',
        interact: true,
        normalize: true,
      });

      wavesurfer.current.load(audioUrl).catch((e) => console.error(e));

      wavesurfer.current.on('ready', () => {
        setIsLoading(false);
        // setHasError(false);
      });

      wavesurfer.current.on('error', (error: any) => {
        console.error('WaveSurfer error:', error);
        // setHasError(true);
        // setIsLoading(false);
      });

      wavesurfer.current.on('play', () => {
        setIsPlaying(true);
      });

      wavesurfer.current.on('pause', () => {
        setIsPlaying(false);
      });

      wavesurfer.current.on('finish', () => {
        setIsPlaying(false);
        updateCurrentAudio(null);
      });

      return () => {
        wavesurfer.current?.pause();
        wavesurfer.current?.destroy();
        updateCurrentAudio(null);
      };
    }
  }, [audioUrl, theme]);

  const handleButtonClick = () => {
    if (isLoading) return;

    updateCurrentAudio(audioId === currentPlayingAudio ? null : audioId);
  };

  useEffect(() => {
    if (audioId === currentPlayingAudio) {
      wavesurfer.current?.play();
    } else {
      wavesurfer.current?.pause();
    }
  }, [currentPlayingAudio, audioId]);

  return (
    <div
      className={clsx(styles.container, styles[theme], {
        [styles.selectable]: selectable,
        [styles.selected]: selected,
      })}
      onClick={onClick}
    >
      <div className={styles.metadata}>
        <img className={styles.image} src={imageSrc} alt="audio logo" />
        {label}
      </div>
      <div className={styles.audioControls}>
        <div
          ref={waveformRef}
          style={{ display: isLoading ? 'none' : 'block', width: 180 }}
        />
        {isLoading ? (
          <Loader
            className={styles.loader}
            size={16}
            color={theme === 'light' ? '#000000' : '#d0d0d0'}
          />
        ) : (
          <>
            {/* <DotsIcon className={styles.dotsIcon} /> */}
            <Button
              width={40}
              height={40}
              className={styles.playButton}
              prefixIcon={
                isPlaying ? (
                  <PauseIcon width={12} height={12} />
                ) : (
                  <PlayIcon width={14} height={16} />
                )
              }
              onClick={handleButtonClick}
            />
            <audio src={audioUrl} />
          </>
        )}
      </div>
    </div>
  );
};

export default AudioBlock;
