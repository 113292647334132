import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Descendant } from 'slate';
import { graphQlCall } from 'graphql/utils';
import { EpisodeStatus } from 'Pages/PodcastGenerator/constants';
import { getBySocket, getToken, createSocket } from 'utils/Utils';
import { ReactComponent as ArrowLeft } from 'Assets/icons/arrowLeft.svg';
import { ReactComponent as PodcastIcon } from 'Assets/icons/Podcaster64.svg';
import { IPodcastEpisode, IPodcastEpisodeSection } from 'types';
import EditorSidebar from 'Components/Common/EditorSidebar/EditorSidebar';
import TextEditor from 'Editors/TextEditor/TextEditor';
import queries from 'graphql/queries';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';

import styles from './EditPodcastEpisode.module.scss';

const EditPodcastEpisode = () => {
  const { episodeId } = useParams<{ episodeId: string }>();

  const [episodeSections, setEpisodeSections] = useState<Descendant[][]>([]);
  const [sections, setSections] = useState<
    (IPodcastEpisodeSection & { hasChanges?: boolean })[]
  >([]);
  const [episode, setEpisode] = useState<IPodcastEpisode>();
  const [loading, setLoading] = useState<boolean>(false);
  const [podcastInfo, setPodcastInfo] = useState<{
    podcastId: string;
    hostName: string;
    podcastName: string;
  }>();
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [hardRenderEditor, setHardRenderEditor] = useState(false);
  const [generatingPercent, setGeneratingPercent] = useState<number>(0);
  const [generatingSection, setGeneratingSection] = useState(false);

  const history = useHistory();

  const sectionsGenerating = sections.some(
    (section) => section.state === 'INITIAL'
  );

  useEffect(() => {
    if (
      episode?.status === EpisodeStatus.GENERATING_AUDIO &&
      !episode.audioUrl &&
      podcastInfo?.podcastId
    ) {
      setIsConfirming(true);
      generateAudio();
    }
  }, [episode?.sections, episode?.audioUrl, podcastInfo?.podcastId]);

  useEffect(() => {
    setLoading(true);
    graphQlCall({
      queryTemplateObject: queries.GET_EPISODE_INFO,
      values: { id: episodeId },
    })
      .then(
        (data: {
          episode: IPodcastEpisode;
          podcastInfo: { id: string; name: string; hostName: string };
        }) => {
          if (!data) return;
          setEpisode(data.episode);
          setPodcastInfo({
            podcastId: data.podcastInfo.id,
            hostName: data.podcastInfo.hostName,
            podcastName: data.podcastInfo.name,
          });
          const sectionsWithIndex = data.episode.sections.map(
            (section, index) => {
              return { ...section, index };
            }
          );
          setSections(sectionsWithIndex);
        }
      )
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [episodeId]);

  useEffect(() => {
    if (
      !sections ||
      sections.length === 0 ||
      !episode?._id ||
      sectionsGenerating
    )
      return;

    const mappedSections = sections.map((section, index) => {
      const sectionParts: Descendant[] = [];

      if (section.title) {
        sectionParts.push({
          type: 'title',
          children: [{ text: section.title }],
          id: section?._id,
        });
      }
      if (index === 0 && episode?.music.introUrl) {
        sectionParts.push({
          type: 'audio',
          data: {
            audioUrl: episode.music.introUrl,
            imageSrc: `https://cdn.autofunnel.ai/podcaster_music/${episode.music.id}.jpg`,
            label: 'Intro',
            id: episode.music.introUrl + index,
          },
          children: [{ text: '' }],
        });
      }
      if (section.text) {
        sectionParts.push({
          type: 'paragraph',
          children: [{ text: section.text }],
        });
      }
      if (index !== sections.length - 1 && episode.music.transitionUrl) {
        sectionParts.push({
          type: 'audio',
          data: {
            audioUrl: episode.music.transitionUrl,
            imageSrc: `https://cdn.autofunnel.ai/podcaster_music/${episode.music.id}.jpg`,
            label: 'Transition',
            id: episode.music.transitionUrl + index,
          },
          children: [{ text: '' }],
        });
      }
      if (index === sections.length - 1 && episode?.music.outroUrl) {
        sectionParts.push({
          type: 'audio',
          data: {
            audioUrl: episode.music.outroUrl,
            imageSrc: `https://cdn.autofunnel.ai/podcaster_music/${episode.music.id}.jpg`,
            label: 'Outro',
            id: episode.music.outroUrl + index,
          },
          children: [{ text: '' }],
        });
      }
      return sectionParts;
    });
    setEpisodeSections([...mappedSections]);
  }, [episode, sections, sectionsGenerating]);

  useEffect(() => {
    const generateText = async (section: IPodcastEpisodeSection) => {
      if (episode) {
        let prompt;
        let notes = '';
        if (section.notes && section.notes !== '') {
          notes = `Use this notes while writing: ${section.notes}`;
        }
        if (section.index === 0) {
          prompt = `You are writing script for podcast episode with a name: ${episode.name}. This is a very first section that is titled: ${section.title}. Make sure to welcome listeners and mention your name is ${podcastInfo?.hostName} and podcast name is ${podcastInfo?.podcastName}. ${notes}  While writing make sure using ${episode.tone} tone. Only return text.`;
        } else if (section.index === sections.length - 1) {
          prompt = `You are writing script for podcast episode with a name: ${episode.name}. This is a last section that titled: ${section.title}. Make sure to say goodby to listeners at the end of this section. ${notes} While writing please make sure using ${episode.tone} tone. Only return text.`;
        } else {
          prompt = `You are writing script for podcast episode with a name: ${episode.name}. This is a middle section that titled: ${section.title}. Do not welcome back listeners you are continuing your script.${notes} While writing please make sure using ${episode.tone} tone. This script is for one person only. Do not mention section title. Only return text. `;
        }

        const payload = {
          episodeId: episode._id,
          sectionId: section._id,
          prompt: prompt,
          jsonMode: true,
          token: getToken(),
        };

        const response: any = await getBySocket({
          emitEventName: 'generate-episode-section-text',
          resultEventName: 'episode-section-response',
          payload,
        });

        return { sectionId: section._id, text: response.result };
      }
    };

    const unfinishedSections = sections.filter(
      (section) => section.state === 'INITIAL'
    );
    if (unfinishedSections.length === 0) return;

    Promise.all(
      unfinishedSections.map((section) => generateText(section))
    ).then((results) => {
      for (const result of results) {
        const section = sections.find((el) => el._id === result?.sectionId);
        if (!section) continue;

        section.text = result?.text;
        section.state = 'TEXT_COMPLETED';
      }

      setSections([...sections]);
    });
  }, [sections, episode, podcastInfo]);

  const generateAudio = () => {
    if (!episode) {
      return;
    }

    const socket = createSocket();

    socket.emit('generate-episode-audio-2', {
      episodeId: episode._id,
      token: getToken(),
    });

    socket.on('episode-audio-response-2', (res) => {
      if (res.percent) {
        setGeneratingPercent(res.percent);

        if (res.percent >= 100) {
          setTimeout(() => handleRedirectToPodcasterPage(), 2000);
        }
      }
    });
  };

  const handleEpisodeConfirm = async () => {
    try {
      setIsConfirming(true);
      if (!episode) {
        return;
      }

      await Promise.all(
        sections
          .filter((section) => section.hasChanges)
          .map((section) =>
            graphQlCall({
              queryTemplateObject: queries.UPDATE_EPISODE_SECTION_MUTATION,
              values: {
                sectionId: section._id,
                episodeId,
                text: section.text,
                title: section.title,
              },
              headerType: 'USER-AUTH',
            })
          )
      );

      await graphQlCall({
        queryTemplateObject: queries.REORDER_EPISODE_SECTIONS,
        values: {
          episodeId,
          sectionIds: sections.map((section) => section._id),
        },
        headerType: 'USER-AUTH',
      });

      generateAudio();
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddNewSection = async () => {
    setGeneratingSection(true);
    const response: any = await getBySocket({
      emitEventName: 'ai-single-prompt',
      resultEventName: 'response-ai-single-prompt',
      payload: {
        text: `I have podcast with "${
          podcastInfo?.podcastName
        }" name and an episode in it with "${episode?.name}" name. 
        In that episode there are ${sections.length} sections of content and 
        the last one named ${sections[sections.length - 1].title}. 
        Generate a new section name that will be at the end of sections list. Just return only one name option without any additions from your side`,
      },
    });
    const data = await graphQlCall({
      queryTemplateObject: queries.ADD_EPISODE_SECTION,
      values: {
        episodeId,
        section: JSON.stringify({
          title: response.result.text.replaceAll('"', ''),
        }),
      },
      headerType: 'USER-AUTH',
    });
    setSections(
      (data.sections as IPodcastEpisodeSection[]).map((section, index) => ({
        ...section,
        index,
      }))
    );
    setGeneratingSection(false);
  };

  const onSectionElementClick = (index: number) => {
    const section = document.getElementById('section ' + (index + 1));
    if (!section) return;

    section.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  };

  const mergeChildrenText = (paragraps: any[])=>{
    let output = "";
    for( const paragrap of paragraps){
      output += paragrap.children[0].text + "\n\n"
    }
    return output;
  }

  const handleTextChange = (index: number, editorValues: Descendant[]) => {
    const section = sections[index];
    if (!section) return;

    const newSectionTitle: any = editorValues.find(
      (el: any) => el.type === 'title'
    );
    if (newSectionTitle && section.title !== newSectionTitle.children[0].text) {
      section.title = newSectionTitle.children[0].text;
      section.hasChanges = true;
    }

    const newSectionParagraphs: any[] = editorValues.filter(
      (el: any) => el.type === 'paragraph'
    );
    if (
      newSectionParagraphs.length > 0 &&
      section.text !== mergeChildrenText( newSectionParagraphs )
    ) {
      section.text = mergeChildrenText( newSectionParagraphs );
      section.hasChanges = true;
    }

    if (section.hasChanges) setSections(Array.from(sections));
  };

  const handleRedirectToPodcasterPage = () => {
    history.push(`/console/podcaster/${podcastInfo?.podcastId}`);
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#d0d0d0" />
        Generation in progress...
      </div>
    );
  }

  const handleChangingSectionTitle = (index: number, value: string) => {
    const updatedSections = [...sections];
    updatedSections[index].title = value;
    updatedSections[index].hasChanges = true;

    setSections(updatedSections);
  };

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.metadata}>
          <Button
            appearance="stroke"
            width={40}
            height={40}
            prefixIcon={<ArrowLeft width={14} height={14} />}
            onClick={handleRedirectToPodcasterPage}
          />
          <div>
            <span className={styles.label}>Episode name: </span>
            {episode?.name}
          </div>
        </div>
        {!isConfirming && (
          <Button width={160} height={40} onClick={handleEpisodeConfirm}>
            Confirm
          </Button>
        )}
      </div>
      <div className={styles.wrapper}>
        {isConfirming && (
          <div className={styles.confirmLoading}>
            <Loader size={30} color="#d0d0d0" />
            <div className={styles.loaderText}>
              In progress... {generatingPercent.toFixed()}%
            </div>
          </div>
        )}
        <EditorSidebar
          header={
            <div className={styles.sidebarTitle}>
              <PodcastIcon />
              Episode Sections
            </div>
          }
          elements={sections}
          elementName="section"
          isElementGenerating={generatingSection}
          onElementClick={onSectionElementClick}
          onAddButtonClick={handleAddNewSection}
          onElementTitleChange={handleChangingSectionTitle}
          onElementDragEnd={(orderedSections) => {
            setSections(orderedSections);
            setTimeout(() => {
              setHardRenderEditor(true);
            });
          }}
          tooltipElements={[
            {
              type: 'regenerate',
              onClick: (section) => {
                section.state = 'INITIAL';
                setSections(Array.from(sections));
              },
            },
            {
              type: 'remove',
              onClick: (section) => {
                graphQlCall({
                  queryTemplateObject: queries.DELETE_EPISODE_SECTION_MUTATION,
                  values: { episodeId, sectionId: section._id },
                  headerType: 'USER-AUTH',
                }).then(() => {
                  setSections(sections.filter((el) => el._id !== section._id));
                  setTimeout(() => {
                    setHardRenderEditor(true);
                  });
                });
              },
            },
          ]}
        />
        <div className={styles.contentWrapper}>
          {sectionsGenerating ? (
            <div className={styles.loaderContainer}>
              <Loader color="#d0d0d0" />
              Generation in progress...
            </div>
          ) : (
            episodeSections.map((section, index) => {
              return (
                <div key={sections[index]?._id}>
                  <div className={styles.section}>
                    <div className={styles.sectionContent}>
                      <div
                        id={'section ' + (index + 1)}
                        className={styles.indexIndicator}
                      >
                        {index + 1}
                      </div>
                      <div className={styles.textWrapper}>
                        <TextEditor
                          hardRender={hardRenderEditor}
                          initialValue={section}
                          onHardRender={() => setHardRenderEditor(false)}
                          onChange={(editorValues) =>
                            handleTextChange(index, editorValues)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {index !== episodeSections.length - 1 && (
                    <hr className={styles.separator} />
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default EditPodcastEpisode;
