import CreateEpisodeForm from 'Components/PodcastGenerator/CreateEpisodeForm/CreateEpisodeForm';
import CreatePodcastForm from 'Components/PodcastGenerator/CreatePodcastForm/CreatePodcastForm';
import SelectGenresForm from 'Components/PodcastGenerator/SelectGenresForm/SelectGenresForm';
import SelectVoiceForm from 'Components/PodcastGenerator/SelectVoiceForm/SelectVoiceForm';

export const podcastGenerationPages = [
  {
    title: 'New Podcast Series',
    subtitle: 'Create a podcast in minutes with our artificial intelligence',
    Form: CreatePodcastForm,
    validation: {
      validate: () => true,
    },
  },
  {
    title: 'Episode',
    subtitle: 'A short description of the podcast episode you want to create',
    Form: CreateEpisodeForm,
    validation: {
      validate: () => true,
    },
  },
  {
    title: 'Music',
    subtitle: 'Choose musical accompaniment for intro, outro and inserts.',
    Form: SelectGenresForm,
    validation: {
      validate: () => true,
    },
  },
  {
    title: 'Voice',
    subtitle: 'Choose the voice you like the most for your podcast episodes',
    Form: SelectVoiceForm,
    validation: {
      validate: () => true,
    },
  },
];

export interface PodcastMusicType {
  id: string | number;
  tags: string[];
  name: string;
  bmp: string;
  image: string;
  introUrl: string;
  outroUrl: string;
  transitionUrl: string;
  transitionStart: number;
  transitionEnd: number;
  introStart: number;
  outroEnd: number;
}

export const podcastsMusics: PodcastMusicType[] = [
  {
    id: 1,
    tags: ['soulful', 'rhythmic', 'flowing'],
    name: 'Launch',
    bmp: '120',
    image: 'https://cdn.autofunnel.ai/podcaster_music/1.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/1-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/1-Outro.mp3',
    transitionUrl: 'https://cdn.autofunnel.ai/podcaster_music/1-Transition.mp3',
    transitionStart: 500.0,
    transitionEnd: 9500.0,
    introStart: 17500.0,
    outroEnd: 9000.0,
  },
  {
    id: 2,
    tags: ['energetic', 'rhythmic', 'vibrant'],
    name: 'Rush',
    bmp: '126',
    image: 'https://cdn.autofunnel.ai/podcaster_music/2.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/2-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/2-Outro.mp3',
    transitionUrl: 'https://cdn.autofunnel.ai/podcaster_music/2-Transition.mp3',
    transitionStart: 400.0,
    transitionEnd: 9000.0,
    introStart: 16700.0,
    outroEnd: 7100.0,
  },
  {
    id: 3,
    tags: ['soulful', 'rhythmic', 'energetic', 'uplifting'],
    name: 'Drive',
    bmp: '120',
    image: 'https://cdn.autofunnel.ai/podcaster_music/3.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/3-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/3-Outro.mp3',
    transitionUrl: 'https://cdn.autofunnel.ai/podcaster_music/3-Transition.mp3',
    transitionStart: 500.0,
    transitionEnd: 9500.0,
    introStart: 17500.0,
    outroEnd: 7500.0,
  },
  {
    id: 4,
    tags: ['dreamy', 'soulful', 'flowing'],
    name: 'Ignite',
    bmp: '149',
    image: 'https://cdn.autofunnel.ai/podcaster_music/4.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/4-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/4-Outro.mp3',
    transitionUrl: 'https://cdn.autofunnel.ai/podcaster_music/4-Transition.mp3',
    transitionStart: 400.0,
    transitionEnd: 8200.0,
    introStart: 14200.0,
    outroEnd: 6400.0,
  },
  {
    id: 5,
    tags: ['slow', 'gritty', 'rhythmic', 'atmospheric'],
    name: 'Radiance',
    bmp: '165',
    image: 'https://cdn.autofunnel.ai/podcaster_music/5.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/5-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/5-Outro.mp3',
    transitionUrl: 'https://cdn.autofunnel.ai/podcaster_music/5-Transition.mp3',
    transitionStart: 700.0,
    transitionEnd: 8000.0,
    introStart: 13500.0,
    outroEnd: 6500.0,
  },
  {
    id: 6,
    tags: ['rhythmic', 'vibrant', 'intense', 'energetic', 'uplifting'],
    name: 'Pulse',
    bmp: '170',
    image: 'https://cdn.autofunnel.ai/podcaster_music/6.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/6-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/6-Outro.mp3',
    transitionUrl: 'https://cdn.autofunnel.ai/podcaster_music/6-Transition.mp3',
    transitionStart: 700.0,
    transitionEnd: 8200.0,
    introStart: 13500.0,
    outroEnd: 7300.0,
  },
  {
    id: 7,
    tags: ['rhythmic', 'vibrant', 'intense', 'energetic', 'uplifting'],
    name: 'Vortex',
    bmp: '175',
    image: 'https://cdn.autofunnel.ai/podcaster_music/7.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/7-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/7-Outro.mp3',
    transitionUrl: 'https://cdn.autofunnel.ai/podcaster_music/7-Transition.mp3',
    transitionStart: 350.0,
    transitionEnd: 6500.0,
    introStart: 12350.0,
    outroEnd: 4800.0,
  },
  {
    id: 8,
    tags: ['soulful', 'dreamy', 'relaxed'],
    name: 'Glide',
    bmp: '114',
    image: 'https://cdn.autofunnel.ai/podcaster_music/8.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/8-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/8-Outro.mp3',
    transitionUrl: 'https://cdn.autofunnel.ai/podcaster_music/8-Transition.mp3',
    transitionStart: 500.0,
    transitionEnd: 10000.0,
    introStart: 19474.0,
    outroEnd: 9480.0,
  },
  {
    id: 9,
    tags: ['soulful', 'dreamy', 'flowing', 'rhythmic'],
    name: 'Surge',
    bmp: '124',
    image: 'https://cdn.autofunnel.ai/podcaster_music/9.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/9-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/9-Outro.mp3',
    transitionUrl: 'https://cdn.autofunnel.ai/podcaster_music/9-Transition.mp3',
    transitionStart: 500.0,
    transitionEnd: 9500.0,
    introStart: 16950.0,
    outroEnd: 8200.0,
  },
  {
    id: 10,
    tags: ['relaxed', 'soulful', 'dreamy'],
    name: 'Ignition',
    bmp: '105',
    image: 'https://cdn.autofunnel.ai/podcaster_music/10.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/10-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/10-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/10-Transition.mp3',
    transitionStart: 600.0,
    transitionEnd: 10900.0,
    introStart: 20000.0,
    outroEnd: 9100.0,
  },
  {
    id: 11,
    tags: ['powerful', 'energetic', 'smooth', 'rhythmic'],
    name: 'Elevate',
    bmp: '110',
    image: 'https://cdn.autofunnel.ai/podcaster_music/11.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/11-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/11-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/11-Transition.mp3',
    transitionStart: 550.0,
    transitionEnd: 10364.0,
    introStart: 19100.0,
    outroEnd: 8730.0,
  },
  {
    id: 12,
    tags: ['slow', 'gritty', 'rhythmic', 'atmospheric', 'cinematic'],
    name: 'Pace',
    bmp: '145',
    image: 'https://cdn.autofunnel.ai/podcaster_music/12.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/12-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/12-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/12-Transition.mp3',
    transitionStart: 400.0,
    transitionEnd: 7900.0,
    introStart: 14480.0,
    outroEnd: 6620.0,
  },
  {
    id: 13,
    tags: ['relaxed', 'smooth', 'dreamy', 'slow'],
    name: 'Chillwave',
    bmp: '85',
    image: 'https://cdn.autofunnel.ai/podcaster_music/13.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/13-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/13-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/13-Transition.mp3',
    transitionStart: 700.0,
    transitionEnd: 13075.0,
    introStart: 13000.0,
    outroEnd: 10588.0,
  },
  {
    id: 14,
    tags: ['relaxed', 'smooth', 'dreamy', 'slow'],
    name: 'Loft',
    bmp: '85',
    image: 'https://cdn.autofunnel.ai/podcaster_music/14.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/14-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/14-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/14-Transition.mp3',
    transitionStart: 700.0,
    transitionEnd: 13060.0,
    introStart: 13050.0,
    outroEnd: 10588.0,
  },
  {
    id: 15,
    tags: ['uplifting', 'atmospheric', 'rhythmic', 'intense'],
    name: 'Reverie',
    bmp: '137',
    image: 'https://cdn.autofunnel.ai/podcaster_music/15.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/15-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/15-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/15-Transition.mp3',
    transitionStart: 440.0,
    transitionEnd: 8320.0,
    introStart: 15300.0,
    outroEnd: 6570.0,
  },
  {
    id: 16,
    tags: ['slow', 'gritty', 'rhythmic', 'atmospheric', 'cinematic'],
    name: 'Echo',
    bmp: '70',
    image: 'https://cdn.autofunnel.ai/podcaster_music/16.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/16-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/16-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/16-Transition.mp3',
    transitionStart: 857.0,
    transitionEnd: 9000.0,
    introStart: 15850.0,
    outroEnd: 5140.0,
  },
  {
    id: 17,
    tags: ['vibrant', 'soulful', 'dreamy', 'energetic'],
    name: 'Sparkle',
    bmp: '130',
    image: 'https://cdn.autofunnel.ai/podcaster_music/1.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/17-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/17-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/17-Transition.mp3',
    transitionStart: 460.0,
    transitionEnd: 8770.0,
    introStart: 15950.0,
    outroEnd: 6920.0,
  },
  {
    id: 18,
    tags: ['relaxed', 'smooth', 'dreamy', 'vibrant'],
    name: 'Bounce',
    bmp: '117',
    image: 'https://cdn.autofunnel.ai/podcaster_music/18.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/18-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/18-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/18-Transition.mp3',
    transitionStart: 500.0,
    transitionEnd: 9500.0,
    introStart: 17692.0,
    outroEnd: 7180.0,
  },
  {
    id: 19,
    tags: ['cinematic', 'atmospheric', 'dreamy', 'uplifting'],
    name: 'Epic',
    bmp: '146',
    image: 'https://cdn.autofunnel.ai/podcaster_music/19.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/19-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/19-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/19-Transition.mp3',
    transitionStart: 822.0,
    transitionEnd: 8630.0,
    introStart: 15200.0,
    outroEnd: 5750.0,
  },
  {
    id: 20,
    tags: ['relaxed', 'smooth', 'dreamy'],
    name: 'Aether',
    bmp: '100',
    image: 'https://cdn.autofunnel.ai/podcaster_music/20.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/20-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/20-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/20-Transition.mp3',
    transitionStart: 500.0,
    transitionEnd: 11400.0,
    introStart: 1110.0,
    outroEnd: 8400.0,
  },
  {
    id: 21,
    tags: ['rhythmic', 'energetic', 'soulful'],
    name: 'Drift',
    bmp: '120',
    image: 'https://cdn.autofunnel.ai/podcaster_music/21.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/21-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/21-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/21-Transition.mp3',
    transitionStart: 400.0,
    transitionEnd: 9500.0,
    introStart: 17250.0,
    outroEnd: 6500.0,
  },
  {
    id: 22,
    tags: ['relaxed', 'smooth', 'dreamy', 'atmospheric', 'cinematic', 'slow'],
    name: 'Haze',
    bmp: '130',
    image: 'https://cdn.autofunnel.ai/podcaster_music/22.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/22-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/22-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/22-Transition.mp3',
    transitionStart: 400.0,
    transitionEnd: 8770.0,
    introStart: 16154.0,
    outroEnd: 6500.0,
  },
  {
    id: 23,
    tags: ['soulful', 'rhythmic', 'smooth', 'energetic', 'dreamy'],
    name: 'Groove',
    bmp: '126',
    image: 'https://cdn.autofunnel.ai/podcaster_music/23.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/23-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/23-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/23-Transition.mp3',
    transitionStart: 400.0,
    transitionEnd: 8850.0,
    introStart: 16430.0,
    outroEnd: 6420.0,
  },
  {
    id: 24,
    tags: ['flowing', 'smooth', 'soulful'],
    name: 'Cascade',
    bmp: '110',
    image: 'https://cdn.autofunnel.ai/podcaster_music/24.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/24-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/24-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/24-Transition.mp3',
    transitionStart: 500.0,
    transitionEnd: 10090.0,
    introStart: 18818.0,
    outroEnd: 8180.0,
  },
  {
    id: 25,
    tags: ['soulful', 'relaxed', 'dreamy', 'slow'],
    name: 'Cadence',
    bmp: '105',
    image: 'https://cdn.autofunnel.ai/podcaster_music/25.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/25-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/25-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/25-Transition.mp3',
    transitionStart: 500.0,
    transitionEnd: 10856.0,
    introStart: 19715.0,
    outroEnd: 8280.0,
  },
  {
    id: 26,
    tags: ['relaxed', 'smooth', 'dreamy'],
    name: 'Rhythm',
    bmp: '91',
    image: 'https://cdn.autofunnel.ai/podcaster_music/26.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/26-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/26-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/26-Transition.mp3',
    transitionStart: 600.0,
    transitionEnd: 12240.0,
    introStart: 12198.0,
    outroEnd: 9890.0,
  },
  {
    id: 27,
    tags: ['flowing', 'relaxed', 'atmospheric', 'slow'],
    name: 'Breeze',
    bmp: '105',
    image: 'https://cdn.autofunnel.ai/podcaster_music/27.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/27-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/27-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/27-Transition.mp3',
    transitionStart: 500.0,
    transitionEnd: 10644.0,
    introStart: 19728.0,
    outroEnd: 8000.0,
  },
  {
    id: 28,
    tags: ['relaxed', 'atmospheric', 'smooth', 'dreamy', 'slow'],
    name: 'Serenity',
    bmp: '120',
    image: 'https://cdn.autofunnel.ai/podcaster_music/28.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/28-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/28-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/28-Transition.mp3',
    transitionStart: 500.0,
    transitionEnd: 9500.0,
    introStart: 16750.0,
    outroEnd: 7000.0,
  },
  {
    id: 29,
    tags: ['relaxed', 'atmospheric', 'smooth', 'dreamy', 'slow'],
    name: 'Focus',
    bmp: '90',
    image: 'https://cdn.autofunnel.ai/podcaster_music/29.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/29-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/29-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/29-Transition.mp3',
    transitionStart: 500.0,
    transitionEnd: 12650.0,
    introStart: 12350.0,
    outroEnd: 9674.0,
  },
  {
    id: 30,
    tags: ['rhythmic', 'powerful', 'energetic'],
    name: 'Edge',
    bmp: '120',
    image: 'https://cdn.autofunnel.ai/podcaster_music/30.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/30-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/30-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/30-Transition.mp3',
    transitionStart: 500.0,
    transitionEnd: 9400.0,
    introStart: 17250.0,
    outroEnd: 7250.0,
  },
  {
    id: 31,
    tags: ['soulful', 'dreamy', 'vibrant'],
    name: 'Boost',
    bmp: '110',
    image: 'https://cdn.autofunnel.ai/podcaster_music/31.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/31-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/31-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/31-Transition.mp3',
    transitionStart: 500.0,
    transitionEnd: 10364.0,
    introStart: 18817.0,
    outroEnd: 8180.0,
  },
  {
    id: 32,
    tags: ['vibrant', 'smooth', 'flowing'],
    name: 'Pop',
    bmp: '105',
    image: 'https://cdn.autofunnel.ai/podcaster_music/32.jpg',
    introUrl: 'https://cdn.autofunnel.ai/podcaster_music/32-Intro.mp3',
    outroUrl: 'https://cdn.autofunnel.ai/podcaster_music/32-Outro.mp3',
    transitionUrl:
      'https://cdn.autofunnel.ai/podcaster_music/32-Transition.mp3',
    transitionStart: 500.0,
    transitionEnd: 10640.0,
    introStart: 19715.0,
    outroEnd: 8570.0,
  },
];

export enum EpisodeStatus {
  INITIAL = 'initiated',
  TEXT_GENERATED = 'TEXT_GENERATED',
  AUDIO_GENERATED = 'AUDIO_GENERATED',
  GENERATING_TEXT = 'GENERATING_TEXT',
  GENERATING_AUDIO = 'GENERATING_AUDIO',
  COMPLETE_ALL = 'COMPLETE_ALL',
}
