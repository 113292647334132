export const UPDATE_EPISODE_MUTATION = {
  operationName: 'updateEpisode',
  query: `
      mutation updateEpisode(
        $id: String!,
        $name: String,
        $tone: String,
        $prompt: String,
        $text: String,
        $voice: String,
        $audioUrl: String
        $description: String
        $coverData: String
        $music: String
        $image: String
      ) {
        updateEpisode(
          id: $id
          name: $name
          tone: $tone
          prompt: $prompt
          text: $text
          voice: $voice
          audioUrl: $audioUrl
          description: $description
          coverData: $coverData
          music: $music
          image: $image
        ) {
          _id
          name
          tone
          voice
          prompt
          text
          audioUrl
          createdAt
          updatedAt
        }
      }
    `,
};
