import clsx from 'clsx';
import { useRef, useState } from 'react';
import { ReactComponent as MoveIcon } from 'Assets/icons/move.svg';
import { ReactComponent as PencilIcon } from 'Assets/icons/edit2.svg';
import AutoExpandTextarea from 'UILib/TextArea/AutoExpandTextarea/AutoExpandTextarea';
import EditableLabel from 'UILib/EditableLabel/EditableLabel';
import ButtonUI from 'UILib/Button/Button';

import styles from './SectionGenerationElement.module.scss';

interface IProps {
  indicator: number;
  text: string;
  notes?: string;
  onSectionNoteChange: (indicator: number, value: string) => void;
  onSectionTitleChange: (indicator: number, value: string) => void;
}

const SectionGenerationElement = ({
  indicator,
  text,
  notes,
  onSectionNoteChange,
  onSectionTitleChange,
}: IProps) => {
  const [addingNotes, setAddingNotes] = useState(false);
  const textArea = useRef<HTMLTextAreaElement>(null);

  const handleNoteSectionBlur = () => {
    if (!notes || notes.trim() === '') {
      setAddingNotes(false);
    }
  };

  const handleNoteChange = (value: string) => {
    if (onSectionNoteChange) {
      onSectionNoteChange(indicator, value);
    }
  };

  const handleAddingNotes = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAddingNotes(true);
    if (textArea.current) {
      textArea.current.focus();
    }
  };

  const handleChangingSectionTitle = (value: string) => {
    if (onSectionTitleChange) {
      onSectionTitleChange(indicator, value);
    }
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.indicator}>
          <div className={styles.defaultIcon}>{indicator}</div>
          <MoveIcon className={styles.hoverIcon} />
        </div>
        <div className={styles.textContainer}>
          <EditableLabel value={text} onChange={handleChangingSectionTitle} />
        </div>
        <div className={styles.moreIcon}>
          {!notes && !addingNotes && (
            <ButtonUI
              width={110}
              height={30}
              appearance="stroke"
              postfixIcon={<PencilIcon fill="#3e3e3e" />}
              onClick={handleAddingNotes}
            >
              Add Note
            </ButtonUI>
          )}
        </div>
      </div>
      <div
        className={clsx(styles.notes, {
          [styles.notesVisible]: notes || addingNotes,
        })}
      >
        <AutoExpandTextarea
          ref={textArea}
          placeholder="Notes"
          theme="light"
          value={notes}
          onBlur={handleNoteSectionBlur}
          onChange={handleNoteChange}
        />
      </div>
    </div>
  );
};

export default SectionGenerationElement;
