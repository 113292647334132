import { useEffect, useState } from 'react';
import { DispatchType, RootState } from 'store/rootReducer';
import { connect } from 'react-redux';
import { notification } from 'antd';
import AccountCard from './AccountCard';
import { IAccount } from '../types';
import AddNewPopup from '../popups/AddNewPopup';
import TransferPopup from '../popups/TransferPopup';
import DeletePopup from '../popups/DeletePopup';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import ConnectExistedPopup from '../popups/ConnectExistedPopup';
import {
  fetchAccountsAction,
  setAccountsAction,
  setActiveAccountAction,
} from 'store/accounts/accountsActions';
import { fetchAgencyData } from 'store/user/userActions';
import { ReactComponent as Accounts } from 'Assets/icons/episodes.svg';
import { ReactComponent as Plus } from 'Assets/icons/plusAdd.svg';
import {
  getAgencyToken,
  getUserId,
  switchSubAccount,
  getUserName,
  getActiveSubId,
} from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'Constants';
import { api } from 'utils/Utils';
import { IAgencyDetails } from 'types';
import Spinner from 'Components/Common/Spinner/Spinner';
import SideMenu from 'Components/SideMenu/SideMenu';
import AgencyLogo from './AgencyLogo';

import s from './AgenciesManage.module.scss';

interface IProps {
  accounts: IAccount[];
  getAccounts: () => void;
  loading: boolean;
  setAccounts: (accounts: IAccount[]) => void;
  setActiveAccount: (account: IAccount | null) => void;
  agencyLogo?: string;
  agencyDetails: IAgencyDetails;
  fetchAgencyDetails: () => void;
}

const AgencyManage = (props: IProps) => {
  const [openedMenu, setOpenedMenu] = useState<string | null>(null);
  const [openAddAccount, setOpenAddAccount] = useState<boolean>(false);
  const [editingAccount, setEditingAccount] = useState<IAccount | null>(null);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openTransfer, setOpenTransfer] = useState<boolean>(false);
  const [openConnectExists, setOpenConnectExists] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [editUser, setEditUser] = useState<boolean>(false);
  const [activeAccount, setActiveAccount] = useState<{
    id?: string;
    thumbnail?: string;
    name: string;
  }>();

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    const id = getActiveSubId();
    setAccounts(props.accounts);
    const account = props.accounts.find((e) => e._id === id);

    setActiveAccount(
      account
        ? {
            id: account._id,
            thumbnail: account?.thumbnail,
            name: account.name,
          }
        : {
            id: props.agencyDetails._id,
            thumbnail: props.agencyDetails?.thumbnail,
            name: props.agencyDetails.name ?? '',
          }
    );
  }, [props.accounts, props.agencyLogo, props.agencyDetails]);

  const fetchAccounts = async () => {
    props.getAccounts();
  };

  const handleAddEdit = async (newAccount: IAccount) => {
    if (newAccount._id === props.agencyDetails._id) {
      props.fetchAgencyDetails();
    } else {
      if (editingAccount) {
        const newAccounts = props.accounts.map((account) => {
          if (account._id === newAccount._id) {
            account = newAccount;
          }
          return account;
        });
        props.setAccounts(newAccounts);

        setEditingAccount(null);
      } else {
        const newAccounts = [...props.accounts];
        newAccounts.push(newAccount);
        props.setAccounts(newAccounts);
      }
    }
    setOpenAddAccount(false);
  };

  const handleCloseAddEditPopup = () => {
    setEditingAccount(null);
    setOpenAddAccount(false);
    setEditUser(false);
  };

  const handleStartEdit = (account: IAccount) => {
    if (account._id === props.agencyDetails._id) {
      setEditUser(true);
    }
    setEditingAccount(account);
    setOpenAddAccount(true);
    setOpenedMenu(null);
  };

  const handleDelete = (account: IAccount) => {
    setOpenedMenu(null);
    setEditingAccount(account);
    setOpenDelete(true);
  };

  const processDelete = async () => {
    if (!editingAccount) {
      return;
    }
    setLoading(true);
    setOpenDelete(false);

    const newAccounts = props.accounts.filter(
      (account) => account._id !== editingAccount._id
    );
    props.setAccounts(newAccounts);
    const id = getUserId() || '';
    const owner = getUserName();
    try {
      await graphQlCall({
        queryTemplateObject: QUERIES.DELETE_SUB_ACCOUNT,
        values: {
          id: editingAccount._id,
        },
        headerType: 'AGENCY_AUTH',
      });
      const link = `/console/agency`;
      notification.success({
        message: 'Success!',
        description: 'Account was deleted',
        placement: 'topRight',
        duration: 2,
        onClose() {
          switchSubAccount(id, owner).then(() => window.open(link, '_self'));
        },
      });
    } catch (error: any) {
      notification.error({
        message: 'Error!',
        description: error.message,
        placement: 'topRight',
        duration: 2,
      });
    }
    setLoading(false);
    setEditingAccount(null);
  };

  const handleStartTransfer = (account: IAccount) => {
    setOpenedMenu(null);
    setEditingAccount(account);
    setOpenTransfer(true);
  };

  const processTransfer = async (email: string) => {
    if (!editingAccount) {
      return;
    }
    setLoading(true);
    setOpenTransfer(false);

    try {
      await graphQlCall({
        queryTemplateObject: QUERIES.TRANSFER_TO_SEPARATE_ACCOUNT,
        values: {
          sub: editingAccount.owner,
          email,
        },
        headerType: 'AGENCY_AUTH',
      });
      const newAccounts = props.accounts.filter(
        (account) => account._id !== editingAccount._id
      );
      props.setAccounts(newAccounts);
      notification.success({
        message: 'Success!',
        description: `Account ${editingAccount.name} was transferred`,
        placement: 'topRight',
        duration: 2,
      });
    } catch (err: any) {
      let message = 'API Error';
      if (err.message.includes('does not exists')) {
        message = `User does not exist. Please make sure this account is subscribed.`;
      } else if (err.message.includes('Please enter')) {
        message =
          'Please enter an email with an active AutoFunnel subscription to compete account transfer.';
      }

      notification.error({
        message: 'Error',
        description: message,
        placement: 'topRight',
        duration: 2,
      });
    }
    setLoading(false);
    setEditingAccount(null);
  };

  const handleCancelTransfer = () => {
    setEditingAccount(null);
    setOpenTransfer(false);
  };

  const handleCancelDelete = () => {
    setEditingAccount(null);
    setOpenDelete(false);
  };

  const processConnectExisting = async (email: string) => {
    setLoading(true);
    try {
      const user: IAccount = await graphQlCall({
        queryTemplateObject: QUERIES.INITIATE_TRANSFER_BY_AGENCY,
        values: {
          email,
        },
        headerType: 'AGENCY_AUTH',
      });
      const newAccounts = [...props.accounts];
      newAccounts.push(user);
      props.setAccounts(newAccounts);
      notification.success({
        message: 'Success!',
        description: `Agency request was sended to ${email}`,
        placement: 'topRight',
        duration: 2,
      });
    } catch (error: any) {
      notification.error({
        message: 'Error!',
        description: error.message,
        placement: 'topRight',
        duration: 2,
      });
    }
    setLoading(false);
    setOpenConnectExists(false);
  };

  const handleUpdateAgencyLogo = async (image: File) => {
    const request = new FormData();
    request.append('file', image);
    const headers = {
      authorization: getAgencyToken(),
    };
    await api(
      `${PAGECRAFT_API_URL}/users/thumbnail/${activeAccount?.id}`,
      'POST',
      request,
      headers
    );

    if (activeAccount?.id === props.agencyDetails._id) {
      props.fetchAgencyDetails();
    } else {
      fetchAccounts();
    }
  };

  return (
    <main className={s.mainContent}>
      <SideMenu />
      {loading && (
        <div className={s.spinnerBlock}>
          <Spinner size={300} />
        </div>
      )}
      {!loading && (
        <section className={s.agencySection}>
          <div
            className={s.bannerContainer}
            style={{
              backgroundImage: `url(${props.agencyDetails?.thumbnail})`,
            }}
          >
            <div className={s.banner} />
            <div className={s.bannerInfo}>
              <AgencyLogo
                onLogoUpdated={handleUpdateAgencyLogo}
                logoUrl={props.agencyDetails?.thumbnail}
              />
              <div className={s.agencyInfo}>
                {props.agencyDetails?.name}
                <div className={s.accountsNumber}>
                  {props.accounts.length} Accounts
                </div>
              </div>
            </div>
          </div>
          <div className={s.contentContainer}>
            <div className={s.userAccountContainer}>
              <div className={s.accounts}>Your Account</div>
              <AccountCard
                onStartEdit={() =>
                  handleStartEdit(props.agencyDetails as IAccount)
                }
                account={
                  {
                    ...props.agencyDetails,
                    notes: props.agencyDetails.customData?.notes,
                  } as IAccount
                }
                onStartDelete={() => {}}
                onStartTransfer={() => {}}
                openMenu={false}
                onClickOpenedMenu={() => {}}
                onCloseMenu={() => setOpenedMenu(null)}
                active={false}
                onSetActive={() =>
                  props.setActiveAccount(props.agencyDetails as IAccount)
                }
                isUserCard
              />
            </div>
            <div className={s.buttonContainer}>
              <div className={s.accounts}>
                <Accounts />
                Accounts
              </div>
              <div
                className={s.addButton}
                onClick={() => setOpenAddAccount(true)}
              >
                Add Account
                <Plus />
              </div>
            </div>
            <div className={s.accountsList}>
              {accounts.map((account) => (
                <AccountCard
                  key={account._id}
                  account={account}
                  active={account._id === activeAccount?.id}
                  onStartDelete={() => handleDelete(account)}
                  onStartEdit={() => handleStartEdit(account)}
                  onStartTransfer={() => handleStartTransfer(account)}
                  openMenu={account._id === openedMenu}
                  onClickOpenedMenu={() => setOpenedMenu(account._id)}
                  onCloseMenu={() => setOpenedMenu(null)}
                  onSetActive={() => props.setActiveAccount(account)}
                  isUserCard={false}
                />
              ))}
            </div>
          </div>
        </section>
      )}
      {openAddAccount && !loading && (
        <AddNewPopup
          onClose={() => handleCloseAddEditPopup()}
          onSubmit={handleAddEdit}
          account={editingAccount}
          editUser={editUser}
        />
      )}
      {openDelete && !loading && (
        <DeletePopup
          confirmDelete={() => processDelete()}
          onClose={() => handleCancelDelete()}
        />
      )}
      {openTransfer && !loading && (
        <TransferPopup
          confirmTransfer={(email: string) => processTransfer(email)}
          onClose={() => handleCancelTransfer()}
        />
      )}
      {openConnectExists && !loading && (
        <ConnectExistedPopup
          onClose={() => setOpenConnectExists(false)}
          onSubmit={processConnectExisting}
        />
      )}
    </main>
  );
};

const mapStateToProps = (state: RootState) => ({
  accounts: state.accounts.accounts,
  loading: state.accounts.loading,
  agencyLogo: state.accounts.agencyLogo,
  agencyDetails: state.user.agencyDetails,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  getAccounts: () => dispatch(fetchAccountsAction()),
  setAccounts: (accounts: IAccount[]) => dispatch(setAccountsAction(accounts)),
  setActiveAccount: (account: IAccount | null) =>
    dispatch(setActiveAccountAction(account)),
  fetchAgencyDetails: () => dispatch(fetchAgencyData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgencyManage);
