import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import QUERIES from 'graphql/queries';
import { IPodcastEpisode, IPodcastWithTotal } from 'types';
import { graphQlCall } from 'graphql/utils';
import { RootState } from '../rootReducer';

export const SET_USER_PODCASTS = 'SET_USER_PODCASTS';
export const SET_PODCAST_EPISODES = 'SET_PODCAST_EPISODES';
export const PODCASTS_LOADING = 'PODCASTS_LOADING';
export const EPISODES_LOADING = 'EPISODES_LOADING';
export const AUDIO_UPDATE = 'AUDIO_UPDATE';
// export const UPDATE_POPUP = 'UPDATE_POPUP';

type DispatchType = ThunkDispatch<RootState, void, Action>;
type GetStateType = () => RootState;

export const fetchUserPodcastsAction = ({
  search,
  sortBy,
  limit,
  skip,
  sortAsc,
  isLoading,
}: {
  search?: string;
  sortBy: string;
  limit: number;
  skip: number;
  sortAsc: boolean;
  isLoading: boolean;
}) => async (dispatch: DispatchType) => {
  if (isLoading) {
    dispatch(podcastsLoading(true));
  }
  try {
    const podcasts: IPodcastWithTotal[] = await graphQlCall({
      queryTemplateObject: QUERIES.GET_PODCASTS_WITH_PAGINATION,
      headerType: 'USER-AUTH',
      values: {
        search,
        sortBy,
        limit,
        skip,
        sortAsc,
      },
    });

    const stateAction = {
      type: SET_USER_PODCASTS,
      payload: podcasts,
    };
    dispatch(stateAction);
  } catch (error) {
    console.error(error);
  }
  dispatch(podcastsLoading(false));
};

export const fetchPodcastEpisodesAction = (podcastId: string) => async (
  dispatch: DispatchType
) => {
  dispatch(episodesLoading(true));
  try {
    const episodes: IPodcastEpisode[] = await graphQlCall({
      queryTemplateObject: QUERIES.GET_ALL_EPISODES_OF_PODCAST,
      headerType: 'USER-AUTH',
      values: { id: podcastId },
    });
    if (!episodes || !Array.isArray(episodes)) return;

    const stateAction = {
      type: SET_PODCAST_EPISODES,
      payload: episodes.reverse(),
    };
    dispatch(stateAction);
  } catch (error) {
    console.error(error);
  }
  dispatch(episodesLoading(false));
};

export const deletePodcastAction = (id: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  try {
    await graphQlCall({
      queryTemplateObject: QUERIES.DELETE_PODCAST,
      values: {
        id,
      },
      headerType: 'USER-AUTH',
    });

    const newPodcasts = [
      ...getState().podcasts?.items.podcasts?.filter(
        (podcast) => podcast._id !== id
      ),
    ];
    const stateAction = {
      type: SET_USER_PODCASTS,
      payload: newPodcasts,
    };
    dispatch(stateAction);
  } catch (error) {
    console.error(error);
  }
};

export const deletePodcastEpisodeAction = (id: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  dispatch(episodesLoading(true));
  try {
    await graphQlCall({
      queryTemplateObject: QUERIES.DELETE_EPISODE_MUTATION,
      values: {
        id,
      },
      headerType: 'USER-AUTH',
    });

    const newEpisodes = [
      ...getState().podcasts.podcastEpisodes.filter(
        (episode) => episode._id !== id
      ),
    ];
    const stateAction = {
      type: SET_PODCAST_EPISODES,
      payload: newEpisodes,
    };
    dispatch(stateAction);
  } catch (error) {
    console.error(error);
  }
  dispatch(episodesLoading(false));
};

export const podcastsLoading = (payload: boolean) => ({
  type: PODCASTS_LOADING,
  payload,
});

export const episodesLoading = (payload: boolean) => ({
  type: EPISODES_LOADING,
  payload,
});

export const updateCurrentAudioAction = (payload: string | null) => async (
  dispatch: DispatchType
) => {
  const stateAction = {
    type: AUDIO_UPDATE,
    payload: payload,
  };

  dispatch(stateAction);
};
