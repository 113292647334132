import React, { useState } from 'react';
import { ReactComponent as BookIcon } from 'Assets/icons/book-icon.svg';
import Modal from 'Components/Common/Modal2/Modal';
import Select from 'Components/Common/Select/Select';
import Button from 'Styled/Button2';
import { IAccount } from 'Components/Accounts/types';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import s from './CopyBookPopup.module.scss';

interface IProps {
  owner: string;
  bookId: string;
  open: boolean;
  accounts: IAccount[];
  onClose: () => void;
  onDuplicate: (payload: {
    targetAccountOwner: string;
    sourceAccountOwner: string;
    bookId: string;
  }) => void;
}

interface ISelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

const CopyBookPopup = (props: IProps) => {
  const [selectedAccountSub, setSelectedAccountSub] = useState('0');

  const accountOptions: ISelectOption[] = props.accounts.map((account) => ({
    label: account.workspace ? account.workspace : account.name,
    value: account.owner,
  }));

  accountOptions.unshift({
    label: 'Select Account',
    value: '0',
    disabled: true,
  });

  const processDuplicateBook = async () => {
    try {
      const response = await graphQlCall({
        queryTemplateObject: QUERIES.DUPLICATE_BOOK_TO_OTHER_ACCOUNT,
        values: {
          bookId: props.bookId,
          targetAccountOwner: selectedAccountSub,
          sourceAccountOwner: props.owner,
        },
        headerType: 'USER-AUTH',
      });

      if (response) {
        console.log('Book duplicated successfully:', response);
        props.onDuplicate({
          targetAccountOwner: selectedAccountSub,
          sourceAccountOwner: props.owner,
          bookId: props.bookId,
        });
      } else {
        console.error('Failed to duplicate book');
      }
    } catch (error) {
      console.error('Error in duplicating book:', error);
    }

    handleClose();
  };

  const handleClose = () => {
    setSelectedAccountSub('0');
    props.onClose();
  };

  return (
    <Modal displayModal={props.open} closeModal={() => handleClose()}>
      <div className={s.modal}>
        <BookIcon className={s.mainImage} />
        <h2 className={s.header}>Copy to Another Account</h2>
        <p className={s.additionalText}>
          Choose the account you want to copy this book
        </p>
        <Select
          options={accountOptions}
          onChange={(account: string) => setSelectedAccountSub(account)}
          value={selectedAccountSub}
        />
        <div className={s.buttonsBlock}>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button
            onClick={() => processDuplicateBook()}
            disabled={selectedAccountSub === '0'}
          >
            Copy
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CopyBookPopup;
