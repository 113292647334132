import { LegacyRef, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Input from 'UILib/Input/Input';

import styles from './EditableLabel.module.scss';

interface IProps {
  size?: 'small' | 'medium' | 'large' | 'extra-large';
  theme?: 'light' | 'dark';
  value: string;
  onChange: (newValue: string) => void;
  onOutsideClick?: () => void;
}

const EditableLabel = ({
  size = 'small',
  theme = 'light',
  value,
  onChange,
  onOutsideClick,
}: IProps) => {
  const [isActive, setIsActive] = useState(false);
  const [contentWidth, setContentWidth] = useState<number>(0);

  const containerRef: LegacyRef<HTMLDivElement> = useRef(null);
  const labelRef: LegacyRef<HTMLDivElement> = useRef(null);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        isActive &&
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        setIsActive(false);
        if (onOutsideClick) onOutsideClick();
      }
    },
    [isActive, onOutsideClick]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (isActive) {
      const inputContainer = document.getElementsByClassName(styles.input);
      if (!inputContainer || !inputContainer[0]) return;

      const inputElement = inputContainer[0].querySelector('input');
      if (!inputElement) return;

      inputElement.focus();
    } else {
      if (!labelRef.current) return;

      setContentWidth(labelRef.current.offsetWidth);
    }
  }, [isActive]);

  return (
    <div
      ref={containerRef}
      className={clsx(styles.container, styles[size])}
      onClick={(e) => {
        e.stopPropagation();
        setIsActive(true);
      }}
    >
      {isActive ? (
        <Input
          theme={theme}
          border="one-line"
          style={{ width: contentWidth + 15 }}
          className={styles.input}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      ) : (
        <div ref={labelRef} className={clsx(styles.label, styles[theme])}>
          {value}
        </div>
      )}
    </div>
  );
};

export default EditableLabel;
