import { useEffect, useState } from 'react';
import Button from 'UILib/Button/Button';

import styles from './SelectGenresForm.module.scss';

const genres = [
  { name: 'Energetic', value: 'energetic' },
  { name: 'Slow', value: 'slow' },
  { name: 'Uplifting', value: 'uplifting' },
  { name: 'Rhythmic', value: 'rhythmic' },
  { name: 'Smooth', value: 'smooth' },
  { name: 'Atmospheric', value: 'atmospheric' },
  { name: 'Dreamy', value: 'dreamy' },
  { name: 'Cinematic', value: 'cinematic' },
  { name: 'Relaxed', value: 'relaxed' },
  { name: 'Vibrant', value: 'vibrant' },
  { name: 'Flowing', value: 'flowing' },
  { name: 'Intense', value: 'intense' },
  { name: 'Soulful', value: 'soulful' },
  { name: 'Powerful', value: 'powerful' },
  { name: 'Gritty', value: 'gritty' },
];

interface IProps {
  handleChange: (key: string, value: string[]) => void;
  validation: { validate: () => boolean };
  data: { [key: string]: string | string[] };
}

const SelectGenresForm = ({ data, handleChange, validation }: IProps) => {
  const [error, setError] = useState({
    genre: '',
    music: '',
  });

  useEffect(() => {
    validation.validate = () => {
      let errorsObj = {
        genre: '',
        music: '',
      };

      if (!data.selectedGenres?.length) {
        errorsObj = {
          ...errorsObj,
          genre: 'Please select a style or mood of music.',
        };
      } else if (!data.musicSample) {
        errorsObj = {
          ...errorsObj,
          music: 'Please Select A Track',
        };
      }

      setError(errorsObj);
      return !!data.selectedGenres?.length && !!data.musicSample;
    };
  }, [validation, data.selectedGenres, data.musicSample]);

  const handleGenreClick = (genre: string) => {
    const selectedGenres = data.selectedGenres ? [...data.selectedGenres] : [];
    if (typeof selectedGenres === 'string') return;

    const index = selectedGenres.findIndex((el) => el === genre);
    let newGenres: string[] = [];
    if (index < 0) {
      newGenres = [genre];
    }
    handleChange('selectedGenres', newGenres);
  };

  return (
    <div className={styles.wrapper}>
      Select a Music Style.
      <div className={styles.genresContainer}>
        {genres.map((genre, index) => (
          <Button
            key={index}
            width={100}
            height={30}
            appearance={
              data.selectedGenres?.includes(genre.value)
                ? 'highlighted'
                : 'stroke'
            }
            onClick={() => handleGenreClick(genre.value)}
          >
            {genre.name}
          </Button>
        ))}
      </div>
      {(error.genre || error.music) && (
        <div className={styles.errorMessage}>{error.genre || error.music}</div>
      )}
    </div>
  );
};

export default SelectGenresForm;
