export const ADD_EPISODE_SECTION = {
  operationName: 'addEpisodeSections',
  query: `mutation addEpisodeSections(
              $episodeId: String!
              $section: String!
              $position: Float
            ) {
              addEpisodeSections(
                  episodeId: $episodeId
                  section: $section
                  position: $position
              ) {
                    _id
                    name
                    tone
                    voice
                    prompt
                    text
                    audioUrl
                    coverData
                    image
                    sections {
                        _id
                        title
                        text
                        notes
                        state
                    }
                    music {
                        id
                        introUrl
                        outroUrl
                        transitionUrl
                        introStart
                        transitionStart
                        transitionEnd
                        outroEnd
                    }
                    createdAt
                    updatedAt
                }
            }`,
};
