import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { IPodcast } from 'types';
import { graphQlCall } from 'graphql/utils';
import { ReactComponent as DeleteIcon } from 'Components/Accounts/icons/delete.svg';
import EditableLabel from 'UILib/EditableLabel/EditableLabel';
import OptionMenu from 'Components/OptionMenu/OptionMenu';
import coverImg from 'Assets/images/hardcodeCover.png';
import queries from 'graphql/queries';

import styles from './PagePodcastItem.module.scss';

enum MenuItems {
  DELETE,
}

interface IProps {
  item: IPodcast;
  onDelete: () => void;
  onUpdate: (updatedItem: IPodcast) => void;
}

const PagePodcastItem = ({ item, onDelete, onUpdate }: IProps) => {
  const history = useHistory();

  const [itemName, setItemName] = useState(item.name);

  const menuItems = [
    {
      label: 'Delete',
      key: MenuItems.DELETE,
      icon: <DeleteIcon />,
    },
  ];

  const handleClick = () => {
    history.push(`/console/podcaster/${item._id}`);
  };

  const handleDeletePodcast = async (): Promise<void> => {
    try {
      await graphQlCall({
        queryTemplateObject: queries.DELETE_PODCAST,
        values: {
          id: item._id,
        },
        headerType: 'USER-AUTH',
      });

      onDelete();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdatePodcastName = async () => {
    if (itemName === item.name) return;

    const updatedPodcast = await graphQlCall({
      queryTemplateObject: queries.UPDATE_PODCAST_MUTATION,
      values: {
        id: item._id,
        name: itemName,
      },
      headerType: 'USER-AUTH',
    });
    onUpdate(updatedPodcast);
  };

  const handleMenuClick = (e: any) => {
    e.domEvent.stopPropagation();
    switch (+e.key) {
      case MenuItems.DELETE: {
        handleDeletePodcast();
        break;
      }
    }
  };

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={styles.infoContainer}>
        <div className={styles.image}>
          <img
            crossOrigin="anonymous"
            src={item.image || coverImg}
            alt="cover"
          />
        </div>
        <div className={styles.info}>
          <EditableLabel
            size="large"
            value={itemName}
            onChange={setItemName}
            onOutsideClick={handleUpdatePodcastName}
          />
          <div className={styles.episodes}>
            {item.episodesCount}{' '}
            {item.episodesCount > 1 ? 'Episodes' : 'Episode'}
          </div>
        </div>
      </div>
      <OptionMenu menuProps={menuProps} />
    </div>
  );
};

export default PagePodcastItem;
